@media screen and (orientation: portrait) {
    
}

@media screen and (orientation: landscape) {
    .formDiv {
        width: 50%;
        float: left;
    }

    .webcamDiv {
        width: 50%;
        float: right;
    }
}